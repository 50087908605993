import React, { useState } from "react"
import { Carousel, Container, Modal} from "react-bootstrap";
import './ProductBanner.scss'
import PlayVideo from "../../Play/PlayVideo"
import ScrollAnimation from "react-animate-on-scroll"
import img from '../../../images/product-details/banner-img.png';
import img2 from '../../../images/product-details/banner-tab.png';
import img3 from '../../../images/product-details/banner-desktop.png';
import HTMLReactParser from 'html-react-parser';
import { useStaticQuery, graphql } from "gatsby";
import ContactUsForm from "../../forms/contact-us-form";

const ProductBanner = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                offices {
                    Title
                    Address
                    Mobile
                    Image {
                      url
                    }
                    URL
                }
            }
        }
    `);

    const [isPlay, setPlay] = useState(false);
    const [showForm, setShowForm] = React.useState(false);
    const [email, setEmail] = React.useState("");

    return (
        <React.Fragment>
            <ScrollAnimation animateIn="fadeInUp">

                <section className="property-card product-banner office-banner">
                    <div className="product-card-img img-zoom office-card-img">
                        <div className="product-image">
                            <picture>
                                <source media="(min-width:1200px)" srcSet={props.data.Image.url} />
                                <source media="(min-width:768px)" srcSet={props.data.Image.url} />
                                <img src={props.data.Image.url} alt={props.data.Image.alternativeText} />
                            </picture>
                        </div>
                    </div>
                    <div className="product-card-details">
                        <h2>{props.data.Meta_Title}</h2>
                        <div className="price-details">
                            <span className="sm-title">
                                <i className="icon-phone"></i>
                                <a href={`tel:${props.data.Mobile}`}>
                                    Tel: {HTMLReactParser(props.data.Mobile.replace(/\n/g, '<br />'))}
                                </a>
                            </span>
                            <span className="sm-title">
                                <i className="icon-mail"></i>
                                <a href="javascript:;" onClick={() => { setEmail(props.data.Email); setShowForm(true); }}>
                                    {HTMLReactParser(props.data.Email.replace(/\n/g, '<br />'))}
                                </a>
                            </span>
                        </div>


                        <div className="d-xl-block buttons">
                            <a href={`/contact/${props.data.URL}/`} className="btn btn-primary">
                                Contact Us
                            </a>
                        </div>


                        <div className="d-xl-block buttons">
                            <a href="/book-a-valuation/" className="btn btn-primary">
                                Request an Appraisal
                            </a>
                        </div>
                    </div>

                </section>

            </ScrollAnimation>
            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId="V8ksSGxo6no"
                isAutoPlay={1}
            />
            <Modal show={showForm} onHide={() => setShowForm(false)} className="enquire-form contact-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="px-0">Contact us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsForm email={props.data.Email} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
};
export default ProductBanner