import React from "react";
import { graphql } from 'gatsby';
import { Header } from "../components/Header/Header";
import ProductBanner from "../components/OfficeDetails/ProductBanner/ProductBanner";
import MenuList from "../components/OfficeDetails/MenuList/MenuList";
import Features from "../components/OfficeDetails/Features/Features";
import SimilarProperties from "../components/Home/Properties/Properties";
import Enquiry from "../components/OfficeDetails/Enquiry/Enquiry";
import { Footer } from "../components/Footer/Footer";
import Map from "../components/map/office-details-map";
import Reviews from "../components/Home/Review/Reviews";
import TeamList from "../components/OfficeDetails/TeamList/TeamList";
import ProductService from "../components/ProductDetails/ProductService/ProductService";
import  SEO from '../components/seo'

const OfficeDetailsTemplate = (props) => {
  const officedet = props.data.glstrapi?.office;
  
  const mapRef = React.useRef(null); 
  return (
    <div className="wrapper slide contact-details-wrapper" id={officedet.Custom_CSS_Class}>
      <Header />
      <SEO title={props.pageContext.meta_title ? props.pageContext.meta_title : officedet.Meta_Title} description={props.pageContext.meta_desc ? props.pageContext.meta_desc : officedet.Meta_Description} />
      <ProductBanner data={officedet} />
      <MenuList data={officedet} />
      <Features data={officedet} />
      {officedet.Latitude && officedet.Longitude &&
        <div ref={mapRef}>
          <Map lat={officedet.Latitude} log={officedet.Longitude} Title={officedet.Title} />
        </div>
      } 
      <TeamList data={officedet.Team_Members} />
      {/* <ProductService data={officedet} /> */}
      <ProductService area={officedet.Title}/>
      {officedet.Title == "Beaconsfield" && <SimilarProperties />}
      <Reviews />
      <Footer />
      <Enquiry />
    </div>
  )
};
export default OfficeDetailsTemplate;

export const pageQuery = graphql`
    query OfficeQuery($id: ID!) {
        glstrapi {
            office(id: $id) {
              _id
              Title
              URL
              Tile_Image {
                url
                alternativeText
              }
              Sub_Title
              Pagename
              Mobile
              Email
              Longitude
              Latitude
              Meta_Description
              Meta_Title
              Description
              Address
              Image {
                url
                alternativeText
              }
              Modules {
                ... on GLSTRAPI_ComponentModulesExploreNow {
                  id
                  CTA_1_Label
                  CTA_2_Label
                  CTA_3_Label
                }
              }
              Office_Hours {
                Title
                id
                Office_Timings {
                  Timing
                  id
                }
              }
              Custom_CSS_Class
              Team_Members(sort: "Sort") {
                Designation
                Sort
                Name
                URL
                Tile_Image {
                  url
                }
                Phone
              }
            } 
        }
    }
`