import React from "react"
import { Link } from "@StarberryUtils"
import { Container } from "react-bootstrap";
import Homeimg01 from '../../../images/home-1.png';
import Homeimg02 from '../../../images/home-2.png';
import img from '../../../images/image-desktop01.png';
import img2 from '../../../images/desktop-home02.png';
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import './TeamList.scss';
import CallIcon from "../../../images/call.svg";

const TeamList = (props) => {
    const TeamLength = props?.data?.length > 4 ? true : false;
    // console.log("text", props.data);
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: TeamLength,
        arrows: false,
        mobileFirst: true,
        autoplay:true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
            {
                breakpoint: 377,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
        ],
    }
    return (
        <section className="similar-properties office-similar-properties" id="team">
            <Container>
                <span class="sub-content">Meet the Team</span>
                <ScrollAnimation animateIn='fadeInUp'>
                    <h3>Who’s behind Bovingdons</h3>
                    <Slider className="slick-arrow" {...settings}>
                        {props.data?.map((teamlist, i) =>
                            <div className="slider-wrap">
                                <div class="slider">
                                    <div className="team-list-img">
                                        <Link to={`/about/our-team/${teamlist.URL}`}>
                                            <picture>
                                                <source media="(min-width:1200px)" srcSet={teamlist.Tile_Image.url} />
                                                <img className="meet-list-img" src={teamlist.Tile_Image.url} alt={teamlist.Tile_Image.alternativeText} />
                                            </picture>
                                        </Link>

                                        <div className="team-list-content">
                                            <h4>{teamlist.Name}</h4>
                                            <span className="price">{teamlist.Designation}</span>

                                            <div className="phone">
                                                <a href={`tel:${teamlist.Phone}`} className="d-flex flex-row">
                                                    <img src={CallIcon} className="mr-2 mt-1" width="15" height="15" />
                                                    <span>{teamlist.Phone}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Slider>
                </ScrollAnimation>
            </Container>
        </section>
    )
};
export default TeamList