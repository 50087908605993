import React, { useState, useRef } from "react"
import { Link } from "@StarberryUtils"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Col, Row } from "react-bootstrap";
import img01 from '../../../images/product-details/contact-img.png';
import img02 from '../../../images/product-details/image-contact.png';
import './Features.scss';
import HTMLReactParser from 'html-react-parser';

/**
 * Feature function component
 * @param {} props 
 * @returns component
 */
const Features = (props) => {
    const anchorRef = useRef(null); // creating ref for anchor tag
    const [isReadMore, setReadMore] = useState(false) // local state for less/expand readmore

    //Handle read more and less class
    React.useEffect(() => {
        anchorRef.current.onclick = () => {
            setReadMore(!isReadMore)

            if (!isReadMore == false) {
                var ReadMoreOffset = document.getElementById("offset_top")
                window.scrollTo(0, ReadMoreOffset.offsetTop)
            }

        }
    }, [isReadMore]);

    return (
        <section className="features" id="about">

            <Container>
                <Row>

                    <Col xl={7}>
                        <ScrollAnimation animateIn='fadeInLeft'>

                            <div className="features-descripton office-features-descripton">
                                <span class="sub-content">details</span>
                                {/* <h3>Key Features & Description</h3> */}

                                <div className={`feature-content ${isReadMore ? 'expand' : ''}`}>
                                    <p>
                                        {HTMLReactParser(props.data.Description.replace())}
                                    </p>
                                </div>

                                <div id="offset_top" className="more">
                                    <span className="read-more" ref={anchorRef}>
                                        {!isReadMore ? "Read More" : "Read Less"}
                                    </span>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </Col>
                    <Col xl={5} className="ml-xl-auto pl-md-0 d-xl-flex">
                        <ScrollAnimation animateIn='fadeInRight' className="ml-xl-auto">
                            <div className="contact-wrapper img-zoom">
                                <div className="office-details">
                                    <div className="office-timing">
                                        <h3>{props.data.Office_Hours.Title}</h3>

                                        {props.data.Office_Hours.Office_Timings.map((time, i) =>
                                            <span key={i}>{time.Timing}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </section>
    )
};
export default Features