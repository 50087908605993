import React, { useState, useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils"
import { Container, Col, Row } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import './MenuList.scss';
import $ from "jquery";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";

const MenuList = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                siteConfig {
                    Facebook_Link
                    Instagram_Link
                    Twitter_Link
                    Footer_Bottom_Links {
                        Label
                        _id
                        Link {
                            _id
                            URL
                        }
                    }
                }
            }
        }
    `);


    const pageUrl = typeof window !== 'undefined' ? window.location.href : ''

    const [shareUrl, setShareUrl] = React.useState(null);
    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' + props.url,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });
    }

    const menuListRef = React.useRef(null);
    const bannerRef = React.useRef(null);

    const scrollFunction = () => {
        if (menuListRef.current != null) {
            let sticky = menuListRef.current.offsetTop;
            if (window.pageYOffset > sticky) {
                menuListRef.current.classList.add("sticky");
            } else {
                menuListRef.current.classList.remove("sticky");
            }
        }
    }

    useEffect(() => {
        $(".detail_about").click(function () {
            $('html, body').animate({
                scrollTop: $("#about").offset().top - 120
            }, 500);
        })

        $(".detail_map").click(function () {
            $('html, body').animate({
                scrollTop: $("#propertymap").offset().top - 200
            }, 500);
        })

        $(".detail_propertymap").click(function () {
            $('html, body').animate({
                scrollTop: $("#propertymap").offset().top - 200
            }, 500);
        })

        $(".detail_team").click(function () {
            $('html, body').animate({
                scrollTop: $("#team").offset().top - 200
            }, 500);
        })

        $(".detail_localarea").click(function () {
            $('html, body').animate({
                scrollTop: $("#localarea").offset().top - 200
            }, 500);
        })

        $(".detail_property").click(function () {
            $('html, body').animate({
                scrollTop: $("#property").offset().top - 200
            }, 500);
        })

        $(".detail_reviews").click(function () {
            $('html, body').animate({
                scrollTop: $("#reviews").offset().top - 200
            }, 500);
        })

        function watchScroll() {
            window.addEventListener("scroll", scrollFunction);
        }

        watchScroll();
        return () => {
            window.removeEventListener("scroll", scrollFunction);
        };

    }, []);

    return (
        <div className="sticky-header-border" ref={menuListRef}>
            <section className="menu-list-n sticky-header">
                <Container>
                    <Row>
                        <Col className="list-menu-section">
                            <ul className="list-menu">
                                <li>
                                    <Link href="javascript:void(0)" className="detail_about">About</Link>
                                </li>
                                <li>
                                    <Link href="javascript:void(0)" className="detail_propertymap">Map</Link>
                                </li>
                                <li>
                                    <Link href="javascript:void(0)" className="detail_team">Meet the Team</Link>
                                </li>
                                <li>
                                    <Link href="javascript:void(0)" className="detail_localarea">Local Area</Link>
                                </li>
                                <li className="detail_property_listed">
                                    <Link href="javascript:void(0)" className="detail_property">Property</Link>
                                </li>
                                <li>
                                    <Link href="javascript:void(0)" className="detail_reviews">Reviews</Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    <div className="social-icons d-none d-xl-flex">
                        <a href="javascript:;" className="sm-content share-text" onClick={() => setShareUrl(!shareUrl)}>
                            Share
                        </a>
                        {shareUrl && <div className="icons-list ml-3">
                            <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={pageUrl} className="my-share-button facebook-share">
                                <FacebookIcon size={32} round={false} borderRadius={`10`} />
                            </FacebookShareButton>
                            <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={pageUrl} className="my-share-button twitter-share">
                                <TwitterIcon size={32} round={false} borderRadius={`10`} />
                            </TwitterShareButton>
                            <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={pageUrl} className="my-share-button linkedin-share">
                                <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                            </LinkedinShareButton>
                            <WhatsappShareButton onClick={() => trackerShare('WhatsappShareButton')} url={pageUrl} className="my-share-button whatsapp-share">
                                <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                            </WhatsappShareButton>
                        </div>}
                    </div>
                </Container>
            </section>
        </div>
    )
};
export default MenuList